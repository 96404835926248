import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import PlentyHeading from '#app/components/typography/plenty-heading'

const PlentyAndTagline = () => {
	let { t } = useTranslation('footer')

	return (
		<div className=" flex flex-col gap-2">
			<div>
				<PlentyHeading as={'h7'}>Plenty&</PlentyHeading>
			</div>
			<div className="flex max-w-full flex-col gap-2 md:max-w-[300px]">
				<PlentyBody size={'md'}>{t('tagline')}</PlentyBody>
			</div>
		</div>
	)
}
export default PlentyAndTagline
