import { Link } from "@remix-run/react"
import { useTranslation } from "react-i18next"
import PlentyBody from "#app/components/typography/plenty-body"
import PlentyHeading from "#app/components/typography/plenty-heading"

const AboutUsInfo = () => {
    let { t } = useTranslation('footer')

    return(
        <div className="flex flex-col gap-4">
        <div>
            <PlentyHeading as={'h7'}>{t('about_us')}</PlentyHeading>
        </div>
        <div className="flex  flex-col gap-2">
            <Link to={'/about-us'} className="group w-fit cursor-pointer">
                <PlentyBody
                    size={'md'}
                    className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
                >
                    {t('about_us')}
                </PlentyBody>
            </Link>
            <Link to={'/stores'} className="group w-fit cursor-pointer">
                <PlentyBody
                    size={'md'}
                    className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
                >
                    {t('find_a_store')}
                </PlentyBody>
            </Link>
            <Link to={'/brands'} className="group w-fit cursor-pointer">
                <PlentyBody
                    size={'md'}
                    className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
                >
                    {t('&_brands')}
                </PlentyBody>
            </Link>
        </div>
    </div>
    )
}
export default AboutUsInfo