import { Icon } from "#app/components/ui/icon"

const PaymentMethod = () => {
    return (
<div className="flex justify-start space-x-2 px-4 pb-14 pt-8 md:flex-row md:justify-end md:space-x-8 md:px-[var(--plenty-padding)] md:pb-6 md:pt-4">
						<Icon className="text-body-md h-10 w-[60px]" name="mobile-pay" />
						<Icon className="text-body-md h-10 w-[60px]" name="debit-card" />
						<Icon className="text-body-md h-10 w-[60px]" name="visa" />
						<Icon className="text-body-md h-10 w-[60px]" name="master-card" />
					</div>


    )
}
export default PaymentMethod