import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import PlentyHeading from '#app/components/typography/plenty-heading'

const ContactInfo = () => {
	let { t } = useTranslation('common')

	return (
		<div className="flex flex-col gap-4">
			<div>
				<PlentyHeading as={'h7'}>{t('contact')}</PlentyHeading>
			</div>
			<div className="flex  flex-col gap-2">
				<PlentyBody size={'md'}>Plenty&</PlentyBody>
				<PlentyBody size={'md'}>Store Torv 1, 8000 Aarhus</PlentyBody>
				<PlentyBody size={'md'}>Support@plentyand.dk</PlentyBody>
				<PlentyBody size={'md'}>+45 82 38 25 50</PlentyBody>
			</div>
		</div>
	)
}
export default ContactInfo
